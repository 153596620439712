<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid, handleSubmit }"
  >
    <b-card>
      <b-form>
        <!-- Course -->
        <b-row class="mt-1">
          <b-col md="12">
            <v-select
              v-model="selectedCourse"
              class="mt-1 mb-2"
              :dir="dir"
              placeholder="Select Course"
              label="name"
              :options="options"
              :loading="isLoading"
              transition="fade"
              @search="debounceInput"
            />
          </b-col>
        </b-row>
        <!-- User role -->
        <b-row class="mt-1">
          <b-col md="12">
            <user-roles-select
              v-model="selectedUserRole"
              hide-label
              :list-type="userRolesListType"
              @update="updateSelectedUserRole"
            />
          </b-col>
        </b-row>
        <!-- Logo -->
        <!-- Note we have temporarili removed thumbnails for first pass of the Training Module -->
        <!-- <b-row v-if="isAdminPortal" class="mt-2 mb-2">
          <b-col md="12">
            <span>
              Logo
            </span>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewImg"
                  :src="thumbnailPreview"
                  width="115"
                  class="rounded mr-5 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-inline-block">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    @click="openMainImageUpload"
                  >
                    <b-spinner
                      v-if="isUpdatingMainImage"
                      small
                    />
                    <span
                      v-if="!isUpdatingMainImage"
                      class="text-nowrap"
                    > Upload</span>
                  </b-button>
                  <b-button
                    v-if="training"
                    id="btn-reset-thumbnail"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="ml-1"
                    variant="outline-secondary"
                    size="sm"
                    :disabled="!hasThumbnail"
                    @click="deleteMainImage"
                  >
                    <b-spinner
                      v-if="isDeletingMainImage" 
                      small
                    />
                    <span
                      v-if="!isDeletingMainImage"
                      class="text-nowrap"
                    >Reset</span>
                  </b-button>
                  <validation-provider
                    v-slot="validationContext"
                    ref="thumbnail"
                    :rules="{ size: MAX_LOGO_SIZE_KB, required: isSelectedTraineeRole && !hasThumbnail }"
                    vid="thumbnail"
                    name="Thumbnail Training"
                  >
                    <b-form-file
                      ref="refInputImg"
                      v-model="urlMainLogo"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose a file"
                      class="mt-2 imageReader hidden"
                      drop-placeholder="Drop file here..."
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row> -->
        <!-- Landing Page -->
        <b-row class="mt-1">
          <b-col md="12">
            <validation-provider
              v-slot="{ errors }"
              ref="title"
              :rules="{ required: isSelectedTraineeRole }"
              name="Title"
            >
              <b-form-group
                :label="$t('Title')"
                label-for="title"
              >
                <b-form-input
                  v-model="title"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Title')"
                  :disabled="isProgramTraining"
                  name="title"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              ref="description"
              :rules="{ required: isSelectedTraineeRole }"
              name="Description"
            >
              <b-form-group
                :label="$t('Description')"
                label-for="description"
              >
                <b-form-textarea
                  v-model="description"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Description')"
                  :disabled="isProgramTraining"
                  name="description"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-1"
          v-if="isProgramTraining"
          sm="3">
          <b-col md="6" v-if="isAdminPortal">
            <b-form-group
              label="Pay per seat"
              label-for="pay_per_seat"
            >
              <b-form-checkbox
                id="pay_per_seat"
                v-model="payPerSeat"
                checked="true"
                name="active-status"
                switch
                inline
              />
            </b-form-group>

          </b-col>
          <b-col md="6"  v-if="payPerSeat">
            <validation-provider
                v-slot="{ errors }"
                ref="seatsAvailable"
                :rules="{ required: true }"
                name="Seats"
              >
              <b-form-group
                :label="$t('Seats')"
                label-for="seatsAvailable"
              >
                <b-form-input
                  v-model="seatsAvailable"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Seats')"
                  name="seatsAvailable"
                  :disabled="!isAdminPortal"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>

            </b-col>
            <b-col :md="isAdminPortal ? 12 : 6">
              <user-roles-select
                v-model="selectedUserRole"
                list-type="library"
                :customLabel="'Role'"
                @update="updateSelectedUserRole"
              />
            </b-col>
        </b-row>

        <!-- License -->
        <div v-if="isProgramTraining">
          <b-row v-if="payPerSeat" class="mt-1">
            <b-col md="6">
              <validation-provider
                v-slot="{ errors }"
                ref="start-date"
                :rules="{ required: true }"
                name="Start Date"
              >
                <b-form-group label="Licence Start Date">
                  <flat-pickr
                    v-model="available_start_date"
                    :state="errors.length > 0 ? false : null"
                    class="form-control"
                    placeholder="Select Date"
                    :config="datePickerConfig"
                    @on-change="onStartDateChange"
                  />
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                v-slot="{ errors }"
                ref="end-date"
                :rules="{ required: true }"
                name="End Date"
              >
                <b-form-group label="Licence End Date">
                  <flat-pickr
                    v-model="available_end_date"
                    :state="errors.length > 0 ? false : null"
                    class="form-control"
                    placeholder="Select Date"
                    :config="datePickerConfig"
                    @on-change="onEndDateChange"
                  />
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>

          </b-row>
        </div>
        <!-- Available -->
        <b-row class="mt-2">
          <b-col md="3">
            <label class="mt-1">Available</label>
          </b-col>
        </b-row>
        <b-row>
          <!-- When -->
          <b-col
            md="12"
            lg="4"
          >
            <validation-provider
              v-slot="validationContext"
              rules="required"
              vid="show-when"
              name="When"
            >
              <b-form-group label-for="show">
                <v-select
                  id="show-when"
                  v-model="happenWhen"
                  label="name"
                  placeholder="When"
                  :options="eventTriggersDisplay"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Event -->
          <b-col
            md="12"
            lg="8"
          >
            <validation-provider
              v-slot="validationContext"
              rules="required"
              vid="show-actionable-event"
              name="Event"
            >
              <b-form-group label-for="show">
                <aom-events-select
                  id="show-actionable-event"
                  v-model="actionableEvent"
                  :options="eventsDisplayComputed"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Custom date -->
            <b-row v-if="showCustomDateField">
              <b-col md="12">
                <aom-date-picker
                  v-model="scheduledDate"
                  :min="minCustomDate"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Period -->
          <b-col
            v-if="showPeriodField"
            md="12"
            lg="6"
          >
            <validation-provider
              v-slot="validationContext"
              vid="time"
              :rules="{ required: true }"
              name="Period"
            >
              <b-form-group label-for="show">
                <v-select
                  id="time"
                  v-model="period"
                  label="#"
                  placeholder="Period"
                  :options="periodList"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Duration -->
          <b-col
            v-if="showDurationField"
            md="12"
            lg="6"
          >
            <validation-provider
              v-slot="validationContext"
              vid="duration"
              :rules="{ required: true }"
              name="Duration"
            >
              <b-form-group label-for="show">
                <v-select
                  id="duration"
                  v-model="duration"
                  label="name"
                  placeholder="Duration"
                  :options="durationUnitsDisplay"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <!-- submit and reset -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="handleSubmit(onSubmit)"
          >
            <b-spinner
              v-if="isSubmitting"
              small
            />
            <span v-if="isSubmitting"> Updating...</span>
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  VBModal,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormFile,
  BFormInput,
  BMedia, 
  BImg,
  BMediaAside,
  BMediaBody,
  BFormCheckbox,
  BFormCheckboxGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import { convertModelToFormData, convertStringToDateIgnoreTimezone, makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import Ripple from "vue-ripple-directive";
import trainingsService from "@/services/trainingsService";
import UserRolesSelect from "@/@aom-core/UserRolesSelect.vue";
import {
  actions,
  eventTriggers,
  eventTriggersDisplay,
  actionableEventDisplay as defaultEventsDisplay,
  actionableEvent as defaultEvents,
  durationUnitsDisplay,
  userRoles,
  logoType,
  programTypes
} from "@/models";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";
import { TrainingThumbnailClass } from "@/models/classes/TrainingClass";
import {
  PRIMARY_DATE_FORMAT
} from "@/libs/utils";
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from "vuex";

const MAX_LOGO_SIZE_KB = '5000';
export default {
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BForm,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,
    UserRolesSelect,
    AomEventsSelect,
    AomDatePicker,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormFile,
    BFormInput,
    BMedia, 
    BImg,
    BMediaAside,
    BMediaBody,
    flatPickr
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isProgramTraining: {
      type: Boolean
    },
    training: {
      type: Object
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters("app", ["isAdminPortal"]),
    thumbnailPreview() {
      return this.thumbnail?.url || require('@/assets/images/logo/logo.png');
    },
    userRolesListType () {
      if (this.isProgramTraining) {
        if (this.program?.type_id === programTypes.TRAINING) {
          return 'trainee';
        }
        return 'participant';
      }
      return 'library';
    },
    eventsDisplayComputed () {
      let isTraineeSelected = this.selectedUserRole?.find(role => role.id === userRoles.TRAINEE);
      if (isTraineeSelected) {
        return [{ id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }];
      }
      return this.eventsDisplay;
    },
    hasThumbnail() {
      return this.thumbnail?.url !== null && this.thumbnail?.url !== undefined;
    },
    isSelectedTraineeRole() {
      return this.selectedUserRole?.find(role => role.id === userRoles.TRAINEE) !== undefined; 
    },
    isAdminPortal() {
      return this.$store.getters["app/isAdminPortal"];
    },
  },
  data() {
    return {
      payPerSeat: false,
      seatsAvailable: 0,
      available_end_date: null,
      available_start_date: null,
      keyword: null,
      selectedCourse: null,
      options: [],
      selectedUserRole: [],
      dir: "ltr",
      isLoading: false,
      isSubmitting: false,
      eventTriggersDisplay,
      defaultEventsDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
      trainingId: 0,
      description: '',
      title: '',
      thumbnail: new TrainingThumbnailClass(),
      isUpdating: false,
      isUpdatingMainImage: false,
      isDeletingMainImage: false,
      uploadThumbnail: null,
      urlMainLogo: undefined,
      datePickerConfig: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        start: {
          maxDate: undefined,
        },
        end: {
          minDate: undefined,
        }
      }
    };
  },
  watch: {
    urlMainLogo(n) {
      this.$refs['thumbnail'].validate().then(success => {
        if(!success.valid) {
          return;
        }
        this.setThumbnail(n);
      });
    },
    training: {
      handler(n) {
        if (n) {
          this.setTraining(n);
        }
      },
      deep: true,
      immediate: true,
    },
    selectedUserRole: {
      handler(roles) {
        let isTraineeSelected = roles?.find(role => role.id === userRoles.TRAINEE);
        if (isTraineeSelected) {
          this.hideParticipantAction();
        }
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    // Only load scorm courses for library
    if (!this.isProgramTraining) {
      this.loadItems();
    }
  },
  methods: {
    onStartDateChange(selectedDates, dateStr) {
      this.datePickerConfig.end.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.datePickerConfig.start.maxDate = dateStr;
    },
    openMainImageUpload() {
      this.$refs.refInputImg.$refs.input.click();
    },
    async deleteMainImage () {
      if(!this.thumbnail) {
        return;
      }
      try {
        const { id } = this.training;
        this.isDeletingMainImage = true;
        this.thumbnail = null;
        await trainingsService.deleteTrainingThumbnail(id);
        this.$toast(makeSuccessToast('Thumbnail deleted successfully.'));
        this.$refs.refPreviewImg.src = require('@/assets/images/logo/logo.png');
        
      } catch (e) {
        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.thumbnail.url;
          return this.$toast(makeErrorToast(e.response.data.message));
        }
        return this.$toast(makeErrorToast('Something went wrong! Program Logo not deleted.'));
      } finally {
        this.isDeletingMainImage = false;
      }
    },
    async createThumbnail() {
      try {
        this.isUpdatingMainImage = true;
        const formData = new FormData();
        Object.keys(this.thumbnail).forEach(key => formData.append(key, this.thumbnail[key]));
        const response = await trainingsService.createTrainingThumbnail(formData);
        this.thumbnail = response.data;
        this.$toast(makeSuccessToast('Thumbnail uploaded successfully.'));
        this.$forceUpdate();
      } catch (e) {
        if(e.response?.data?.message) {
          return this.$toast(makeErrorToast(e.response.data.message));
        } 
        return this.$toast(makeErrorToast('Something went wrong! Thumbnail not saved.'));
      } finally {
        this.isUpdatingMainImage = false;
      }
    },
    async updateThumbnail() {
      try {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to update the thumbnail ?', {
            title: 'Update Thumbnail',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Update',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              const { id } = this.training;
              this.isUpdatingMainImage = true;
              const response = await trainingsService.updateTrainingThumbnail(
                id,
                convertModelToFormData({...this.thumbnail, _method: 'PATCH'})
              );
              const {data} = response;
              this.$refs.refPreviewImg.src = data.url;
              this.thumbnail = data;
              this.$toast(makeSuccessToast('Thumbnail updated successfully.'));
              this.isUpdatingMainImage = false;
            }
          });
      } catch (e) {
        console.log(e);
        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.thumbnail.url;
          return this.$toast(makeErrorToast(e.response.data.message));
        } 
        return this.$toast(makeErrorToast('Something went wrong! Thumbnail not saved.'));
      } finally {
        this.isUpdatingMainImage = false;
      }
    },
    
    setThumbnail(file) {
      this.thumbnail = {
        ...this.thumbnail,
        file: file
      };
      if (this.training) {
        this.updateThumbnail();
      } else {
        this.createThumbnail();
      }
    },
    hideParticipantAction() {
      if (this.happenWhen?.id !== eventTriggers.ON) {
        this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
      }
      this.scheduled_actions = this.getScheduledActions();
      if (this.scheduled_actions[0]?.actionable_event_id) {
        let isKeyDate = this.eventsDisplay.find(event => event.id === this.scheduled_actions[0]?.actionable_event_id)?.isKeyDate;
        if (!isKeyDate) {
          this.actionableEvent = undefined;
        }
      }
    },
    getScheduledActions() {
      const scheduledAction = {
        action_id: actions.SHOW
      };

      let newPeriod = 0;
      let newDuration = this.duration?.id;
      // Convert relative_value
      if (this.happenWhen) {
        switch (this.happenWhen.id) {
          case eventTriggers.BEFORE:
            newPeriod = -this.period;
            break;
          case eventTriggers.ON:
            newPeriod = 0;
            newDuration = undefined;
            break;
          case eventTriggers.AFTER:
            newPeriod = this.period;
            break;
        }
      }

      // Assign values to scheduledAction
      // Custom date
      if (this.actionableEvent?.id === defaultEvents.CUSTOM_DATE && this.scheduledDate) {
        scheduledAction.absolute_date = this.scheduledDate;
      } else {
        scheduledAction.actionable_event_id = this.actionableEvent?.id;
      }
      if (newPeriod) {
        scheduledAction.relative_value = newPeriod;
      }
      if (newDuration) {
        scheduledAction.relative_unit_id = newDuration;
      }

      return [scheduledAction];
    },
    async onSubmit() {
      this.$emit("onSubmit", {
        course_id: this.selectedCourse.id,
        course_name: this.selectedCourse.name,
        roles: this.selectedUserRole,
        scheduled_actions: this.getScheduledActions(),
        title: this.title,
        description: this.description,
        thumbnail: this.thumbnail,
        pay_per_seat: this.payPerSeat,
        available_start_date: this.available_start_date,
        available_end_date: this.available_end_date,
        seats_available: this.seatsAvailable,
      });
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      this.isLoading = true;
      try {
        const response = await trainingsService.getLibraryScormCourses({
          columnFilters: this.keyword
            ? [
                {
                  field: "full_name",
                  value: this.keyword,
                },
              ]
            : [],
        });
        this.options = response.data.items.map(course => ({
          id: course.id,
          name: course.title,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    setTraining(training) {
      this.title = training.title;
      this.description = training.description;
      this.payPerSeat = !!training.pay_per_seat;
      this.seatsAvailable = training.seats_available;
      this.available_start_date = training.available_start_date;
      this.available_end_date = training.available_end_date;
      this.trainingId = training.id;
      this.selectedCourse = {id: training.course_id, name: training.course_name};
      this.selectedUserRole = training.roles?.map(r => ({id: r.id, name: r.name}));
      this.thumbnail = training.image;

      // Parse scheduled action
      if (training.scheduled_action) {
        const scheduledAction = training.scheduled_action;

        if (!scheduledAction.relative_value) {
          this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
        } else {
          if (scheduledAction.relative_value < 0) {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.BEFORE);
          } else {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.AFTER);
          }
        }

        this.$nextTick(() => {
          this.actionableEvent = scheduledAction.absolute_date
              ? { id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }
              : this.eventsDisplay.filter(a => a.id === scheduledAction.actionable_event_id).shift();
          this.period = Math.abs(scheduledAction.relative_value);
          this.duration = durationUnitsDisplay.filter(d => d.id === scheduledAction.relative_unit_id)[0];
          this.scheduledDate = scheduledAction.absolute_date ? convertStringToDateIgnoreTimezone(scheduledAction.absolute_date) : undefined;
        });
      }
    },
    updateSelectedUserRole(role) {
      this.selectedUserRole = role;
    },
    clearForm() {
      this.selectedCourse = null;
      this.selectedUserRole = [];
    },
    cancel() {
      this.clearForm();
      this.$emit("cancel");
    }
  },
  setup(props) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      minCustomDate,
      eventsWithoutMatchesDisplay
    } = useActionableEvent(props.isProgramTraining);

    return {
      MAX_LOGO_SIZE_KB,
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      getValidationState,
      minCustomDate,
      eventsWithoutMatchesDisplay
    };
  },
};
</script>
